import { Page } from "@/templates";
import { GetServerSideProps } from "next";
import { getSlugStory, useStoryblok } from "@/lib/storyblok";
import { OrganizationJsonLd, SocialProfileJsonLd } from "next-seo";

import { SbComponent } from "@/components";
import { ThemeElement } from "@hybrbase/themes";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function Home({ story }) {
  const dataStory = useStoryblok(story);

  return (
    <>
      <SocialProfileJsonLd
        type="Organization"
        name="HYBRBASE"
        url="https://hybrbase.com/"
        sameAs={[
          "https://www.facebook.com/hybrbase/",
          "https://www.instagram.com/hybrbase/",
          "https://www.linkedin.com/company/hybrbase/",
          "https://twitter.com/hybrbase/",
        ]}
      />
      <OrganizationJsonLd
        type="Corporation"
        logo="https://hybrbase.com/logo.png"
        name="HYBRBASE"
        address={{
          streetAddress: "Floor 11, A11.12, Centana, 36 Mai Chi Tho",
          addressLocality: "Ho Chi Minh",
          addressRegion: "Thu Duc",
          postalCode: "700000",
          addressCountry: "VI",
        }}
        contactPoint={[
          {
            telephone: "+84 334 656 760",
            contactType: "customer service",
            areaServed: "VI",
            availableLanguage: ["English", "Vietnamese"],
          },
        ]}
        sameAs={["https://hybrbase.com/"]}
        url="https://hybrbase.com/"
      />

      {dataStory?.content?.body?.map((blok) => {
        return <SbComponent blok={blok} key={blok._uid} />;
      })}
    </>
  );
}

Home.getLayout = function getLayout(page) {
  const {
    props: { story, headerStory, footerStory, pageContext },
  } = page;

  return (
    <Page
      story={story}
      headerStory={headerStory}
      footerStory={footerStory}
      pageContext={pageContext}
    >
      {page}
    </Page>
  );
};

export const getServerSideProps: GetServerSideProps = async ({
  locale,
  defaultLocale,
  req,
  resolvedUrl,
}) => {
  const language = locale || defaultLocale;

  const { story, headerStory, footerStory, pagePath } = await getSlugStory({
    language,
    storySlug: "/home",
    req,
    resolvedUrl,
  });
  return {
    props: {
      story,
      footerStory,
      headerStory,
      // Get the automatic refetches to keep our data fresh from SSR by dehydrate the client on the server
      pageContext: {
        name: story?.slug,
        theme: story?.content?.theme || ThemeElement.Default,
        resolvedUrl,
        language: locale || defaultLocale,
        seo: {
          defaultCanonicalUrl: pagePath.canonicalUrl,
          // languageAlternates: pagePath.languageAlternates,
        },
      },
    },
  };
};

// export const getStaticProps: GetStaticProps = async ({
//   locale,
//   defaultLocale,
// }) => {
//   const pageName: QueryPageName = QueryPageName.Home;
//   const slug = "/home";
//   const language = locale || defaultLocale;

//   const { story, footerStory } = await getSlugStory({ language, slug });

//   return {
//     props: {
//       pageName,
//       story,
//       footerStory,
//       pageContext: {
//         resolvedUrl: slug,
//         language: locale || defaultLocale,
//       },
//     },
//     revalidate: 5 * 60, // every 5 minutes
//   };
// };

//
// Service side example
//

// export const getServerSideProps: GetServerSideProps = async ({
//   locale,
//   defaultLocale,
//   req,
//   resolvedUrl,
// }) => {
//   const queryName: PageQueryName = PageQueryName.Home;
//   const stripUrl = "/home";
//   const language = locale || defaultLocale;
//   const zustandStore = initAppStore();

//   const queryClient = new QueryClient();
//   await queryClient.prefetchQuery(
//     [
//       queryName,
//       {
//         language,
//         resolvedUrl: stripUrl,
//       },
//     ],
//     () => getSlugStory({ language, slug: stripUrl })
//   );

//   const { canonicalUrl, languageAlternates } = await getPageUrls({
//     req,
//     slug: resolvedUrl,
//   });

//   return {
//     props: {
//       initialZustandState: JSON.parse(JSON.stringify(zustandStore.getState())),

//       queryName,
//       // Get the automatic refetches to keep our data fresh from SSR by dehydrate the client on the server
//       dehydratedState: dehydrate(queryClient),
//       pageContext: {
//         resolvedUrl: stripUrl,
//         language: locale || defaultLocale,
//         isMobile: isDetectDevice("mobile", req),
//         seo: {
//           defaultCanonicalUrl: canonicalUrl,
//           languageAlternates,
//         },
//       },
//     },
//   };
// };
